import { IHttpClient } from '../@interfaces/IHttpClient';
import { ActivityMapper } from '../mappers/ActivityMapper';

export class ActivitiesService {
  constructor(private readonly httpClient: IHttpClient) {}

  getActivities(
    fromDate: string,
    toDate: string,
    userID?: string,
    categories?: string[],
  ) {
    return this.httpClient.post('/report/activities', {
      body: {
        filter: {
          fromDate,
          toDate,
          userID,
          categories,
        },
      },
    });
  }

  getActivity(userID: string, activityID: string) {
    return this.httpClient.get(`/activity/${userID}/activity/${activityID}`);
  }

  async getByUserId(userId: string, { status = 'open' } = {}) {
    const { body, statusCode } = await this.httpClient.get(
      `/activity/${userId}/activities?status=${status}`,
    );

    if (statusCode !== 200) {
      return { statusCode, body };
    }

    return {
      statusCode,
      body: (body.activities ?? []).map(ActivityMapper.toDomain),
    };
  }

  async exportActivities(
    fromDate: string,
    toDate: string,
    userID?: string,
    categories?: string[],
  ) {
    const response = await this.httpClient.post('/report/activities/excel', {
      body: {
        filter: {
          fromDate,
          toDate,
          userID,
          categories,
        },
      },
      responseType: 'blob',
    });

    if (response.statusCode !== 200) {
      return response;
    }

    return {
      ...response,
      body: new Blob([response.body]),
    };
  }

  async viewBySubmission(userID: string, dateFrom: string, dateTo: string) {
    const { body, statusCode } = await this.httpClient.get(
      `/activity/${userID}/activities/submissionPeriod/${dateFrom}/${dateTo}`,
    );

    if (statusCode !== 200) {
      return { statusCode, body };
    }

    const submissionPeriod = body.activities.map(ActivityMapper.toDomain);

    return {
      statusCode,
      body: submissionPeriod,
    };
  }

  async generateSubmissionPdf(
    dateFrom?: string,
    dateTo?: string,
    userID?: string,
  ) {
    const myURL = `/activity/${userID}/activities/submissionPeriod/${dateFrom}/${dateTo}/pdf`;
    const response = await this.httpClient.post(myURL, {
      body: {},
      responseType: 'blob',
    });

    if (response.statusCode !== 200) {
      return response;
    }

    return {
      ...response,
      body: new Blob([response.body]),
    };
  }

  async exportSubmissions(
    fromDate: string,
    toDate: string,
    userID?: string,
    categories?: string[],
    useSubmissionDates?: boolean,
  ) {
    const response = await this.httpClient.post('/report/activities/excel', {
      body: {
        filter: {
          fromDate,
          toDate,
          userID,
          categories,
          useSubmissionDates,
        },
      },
      responseType: 'blob',
    });

    if (response.statusCode !== 200) {
      return response;
    }

    return {
      ...response,
      body: new Blob([response.body]),
    };
  }

  async delete(userID: string, id: string) {
    return this.httpClient.delete(`/activity/${userID}/activity/${id}`);
  }

  // TODO: type the body after map all API param types (after company/contacts)
  async create(userID: string, body: any) {
    return this.httpClient.post(`/activity/${userID}/activity/new`, {
      body,
    });
  }

  // TODO: type the body after map all API param types (after company/contacts)
  async update(userID: string, activityID: string, body: any) {
    return this.httpClient.put(`/activity/${userID}/activity/${activityID}`, {
      body,
    });
  }
}
